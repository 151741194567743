



























































































































import {
  BTable,
  BCard,
  BRow,
  BCol,
  BButton,
  BBadge,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BFormCheckbox,
} from "bootstrap-vue"
import { defineComponent, Ref, ref } from "@vue/composition-api"
import {
  getStrategies,
  tableColumns,
  Strategy,
  StrategyEnvironment,
  envToText,
} from "./useCrashStrategies"
import AddCrashStrategyModal from "./AddCrashStrategyModal.vue"
import { deleteDoc, doc } from "firebase/firestore"
import {
  apostaGanhaCrashStrategiesCollection,
  blazeDoubleStrategiesRef,
} from "@/@core/firebase"
import EnvironmentEntity from "@/domain/entities/EnvironmentEntiry"
import useEnvironment from "@/domain/services/useEnvironment"
import EditEnvironment from "../components/EditEnvironment.vue"

export default defineComponent({
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    AddCrashStrategyModal,
    BTabs,
    BTab,
    BFormCheckbox,
    EditEnvironment,
  } as any,
  setup(props, context) {
    const columns = tableColumns

    const { getEnvironment } = useEnvironment("apostaGanha")
    const environmentData = ref<EnvironmentEntity>({
      id: "",
      activated: false,
      botKey: "",
      chatId: "",
      mode: "full",
    })

    const currentEnv = ref<EnvironmentEntity>({
      id: "",
      activated: false,
      botKey: "",
      chatId: "",
      mode: "full",
    })

    const strategies: { [key in StrategyEnvironment]: any } = {
      [StrategyEnvironment.Production]: getStrategies(
        StrategyEnvironment.Production
      ),
      [StrategyEnvironment.Aviator10]: getStrategies(
        StrategyEnvironment.Aviator10
      ),
      [StrategyEnvironment.Test]: getStrategies(StrategyEnvironment.Test),
    }

    const mode = ref("new")

    const strategyEnabled = ref(false)
    const editEnvModal = ref(null) as Ref<any>

    const modalStrategy = ref(null) as Ref<any>
    const currentEnvironment = ref(
      StrategyEnvironment.Production
    ) as Ref<StrategyEnvironment>

    const currentItem = {
      name: "",
      sequences: [],
      targets: [],
      createdAt: "",
      gales: 2,
      enabled: false,
    } as Strategy

    const newStrategy = (
      key: StrategyEnvironment = StrategyEnvironment.Production
    ) => {
      mode.value = "new"
      currentItem.name = ""
      currentItem.sequences = []
      currentItem.targets = []
      currentItem.createdAt = ""
      currentItem.gales = 2
      currentItem.enabled = false
      modalStrategy.value.show()
    }

    const updateModal = (event: any) => {
      modalStrategy.value = event
    }

    const deleteStrategy = (itemId: string) => {
      ;(context.root as any)
        .$swal({
          title: "Tem certeza?",
          text: "Deseja excluir essa estratégia?",
          icon: "question",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sim, delete!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then((result: any) => {
          if (result.value) {
            deleteDoc(doc(apostaGanhaCrashStrategiesCollection, itemId))
            ;(context.root as any).$swal({
              icon: "success",
              title: "Deletado!",
              text: "A estratégia foi deletada.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            })
          }
        })
    }

    const editStrategy = (item: Strategy) => {
      mode.value = "edit"
      currentItem.id = item.id
      currentItem.name = item.name
      currentItem.sequences = JSON.parse(JSON.stringify(item.sequences))
      currentItem.targets = JSON.parse(JSON.stringify(item.targets))
      currentItem.createdAt = item.createdAt
      currentItem.enabled = item.enabled
      currentItem.gales = item.gales
      modalStrategy.value.show()
    }

    const changeEnvironment = async (key: string) => {
      try {
        const current = await getEnvironment("crash", key)

        environmentData.value.id = current.id
        environmentData.value.activated = current.activated
        environmentData.value.botKey = current.botKey
        environmentData.value.chatId = current.chatId
        environmentData.value.mode = current.mode
      } catch {
        environmentData.value.id = key
        environmentData.value.activated = false
        environmentData.value.botKey = ""
        environmentData.value.chatId = ""
        environmentData.value.mode = "full"
      }

      currentEnv.value = Object.assign({}, environmentData.value)

      currentEnvironment.value = key as StrategyEnvironment
    }

    const showEditEnvModal = () => {
      environmentData.value.activated = currentEnv.value.activated
      environmentData.value.botKey = currentEnv.value.botKey
      environmentData.value.chatId = currentEnv.value.chatId
      environmentData.value.id = currentEnv.value.id
      environmentData.value.mode = currentEnv.value.mode

      editEnvModal.value.show()
    }

    const envUpdadated = (data: EnvironmentEntity) => {
      currentEnv.value = Object.assign({}, data)
    }

    changeEnvironment(StrategyEnvironment.Production)

    return {
      columns,
      StrategyEnvironment,
      strategies,
      modalStrategy,
      currentEnvironment,
      currentItem,
      envToText,
      newStrategy,
      updateModal,
      editStrategy,
      deleteStrategy,
      strategyEnabled,
      mode,
      environmentData,
      currentEnv,
      envUpdadated,
      showEditEnvModal,
      editEnvModal,
      changeEnvironment,
    }
  },
})
